import { Box } from '@chakra-ui/react';

const TrialDisclaimer = () => (
  <Box>
    CAUTION - Investigational device. Limited by Federal (or United States) law to investigational
    use. It is intended for research purposes only and should not be used for clinical diagnosis or
    treatment. Use of this device is at the sole discretion and risk of the user. Any results
    obtained with this device should be interpreted with caution and may not be representative of
    clinical outcomes.
  </Box>
);

export default TrialDisclaimer;
