import { Box, Center, Heading, Spinner } from '@chakra-ui/react';

const LoadingPage = ({ message }: { message?: string }) => (
  <Center h="100vh">
    <Box textAlign="center">
      <Spinner size="xl" mb={5} />
      <Heading size="md">{message || 'Loading...'}</Heading>
    </Box>
  </Center>
);

export default LoadingPage;
