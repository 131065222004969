import { Button as ChakraButton, type ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { forwardRef, type ReactNode } from 'react';

import isNullOrUndefined from '~sf/utils/isNullOrUndefined';
import mixpanel from '~sf/vendor/mixpanel';

export type ButtonProps = {
  eventName: string;
  eventProps?: { [key: string]: string | number | boolean };
  children?: ReactNode;
} & ChakraButtonProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, eventName, eventProps, className, onClick, ...rest }: ButtonProps, ref) => {
    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
      mixpanel.track(`click ${eventName}`, { ...eventProps });

      if (onClick) {
        onClick(e);
      }
    };

    const eventClassName = !isNullOrUndefined(eventName)
      ? `button_${eventName.replaceAll(' ', '_')}`
      : '';
    const combinedClassName = `${eventClassName} ${className}`;

    return (
      <ChakraButton ref={ref} className={combinedClassName} onClick={onClickHandler} {...rest}>
        {children}
      </ChakraButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
