import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';

import Routes from '~sf/components/setup/Routes';

import { AppRoutes } from './constants';
import renderRoutes from './utils/renderRoutes';
import type { RouteDefinition } from './utils/types';

const routeDefinitions: RouteDefinition[] = [
  {
    path: AppRoutes.SignIn,
    lazyComponent: React.lazy(async () => import('../../../pages/SignInPage')),
  },
  {
    path: AppRoutes.ForgotPasswd,
    lazyComponent: React.lazy(async () => import('../../../pages/ForgotPasswordPage')),
  },
  {
    path: AppRoutes.MagicLink,
    lazyComponent: React.lazy(async () => import('../../../pages/MagicLinkPage')),
  },
  {
    path: AppRoutes.ForgotPasswdReset,
    lazyComponent: React.lazy(async () => import('../../../pages/ValidatePasswordPage')),
  },
  {
    path: AppRoutes.MFA,
    lazyComponent: React.lazy(async () => import('../../../pages/MfaAuthPage')),
  },
];

const UnauthenticatedRoutes = () => {
  const { pathname } = useLocation();

  return (
    <Routes>
      {renderRoutes(routeDefinitions)}

      <Route
        path="*"
        element={<Navigate to={AppRoutes.SignIn} state={{ redirectTo: pathname }} replace />}
      />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
