import { useQuery } from '@tanstack/react-query';
import { ApiError } from 'openapi-typescript-fetch';

import { profileClient } from '~sf/api/clients';
import { QueryKey } from '~sf/api/query';

import useNotify from './useNotify';
import useStore from './useStore';

const useProfile = () => {
  const { loggedIn } = useStore((state) => ({
    loggedIn: state.loggedIn,
  }));
  const notify = useNotify();

  const { data: profile } = useQuery({
    queryKey: QueryKey.Profile,
    queryFn: async () => profileClient({}),
    onError: (e: Error) => {
      notify({
        title: 'Unable to load profile',
        status: 'error',
        description: e instanceof ApiError ? e.message : 'Please try again later',
      });
    },
    staleTime: 100,
    enabled: loggedIn,
  });

  return profile?.data;
};

export default useProfile;
