import axios from 'axios';

import { releaseVersionClean } from '~sf/components/app/AppVersion';

type VersionResponse = {
  version: number;
};

async function checkVersionIsLatest(): Promise<boolean> {
  const latestVersion = await getLatestVersion();

  if (latestVersion === -1) {
    // we don't care if it fails to load the version, it's just a nice to have
    return true;
  }

  return latestVersion === releaseVersionClean;
}

async function getLatestVersion() {
  const res = await axios
    .get<VersionResponse>('/version.json')
    .catch(() => ({ data: { version: -1 } }));

  return res.data.version;
}

export default checkVersionIsLatest;
