import React from 'react';
import { shallow } from 'zustand/shallow';

import type { Entitlements } from '~sf/models';
import useEntitlementsCheck from '~sf/utils/hooks/useEntitlementsCheck';
import useStore from '~sf/utils/hooks/useStore';
import isUndefined from '~sf/utils/isUndefined';
import typedKeys from '~sf/utils/typedKeys';

import AuthenticatedRoutes from './AuthenticatedRoutes';
import { AppRouteEntitlements } from './constants';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';

const LoadingPage = React.lazy(async () => import('~sf/pages/LoadingPage'));

const MainRouter = () => {
  const { loggedIn, checkedAuth, entitlements, entitlementsChecked } = useStore(
    (state) => ({
      loggedIn: state.loggedIn,
      checkedAuth: state.checkedAuth,
      entitlements: state.entitlements,
      entitlementsChecked: state.entitlementsChecked,
    }),
    shallow,
  );

  const entitledRoutes = typedKeys(AppRouteEntitlements).filter(
    (route) => !isUndefined(AppRouteEntitlements[route]),
  );
  const entitlementList = entitledRoutes.map(
    (route) => AppRouteEntitlements[route],
  ) as Entitlements[];
  const areRoutesEntitled = useEntitlementsCheck(...entitlementList);

  const firstEntitledRoute = entitledRoutes.find((_, index) => areRoutesEntitled[index]);

  if (!checkedAuth) {
    return <LoadingPage />;
  }

  if (!loggedIn) {
    return <UnauthenticatedRoutes />;
  }

  if (!entitlementsChecked) {
    return <LoadingPage />;
  }

  return (
    <AuthenticatedRoutes
      // resources={resources}
      entitlements={entitlements}
      homeResource={firstEntitledRoute}
    />
  );
};

export default MainRouter;
