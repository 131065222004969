import { Outlet } from 'react-router-dom';

import Sidebar from '~sf/components/app/Sidebar';
import ErrorBoundary from '~sf/components/ui/ErrorBoundary';

const Dashboard = () => (
  <Sidebar>
    <ErrorBoundary mode="page">
      <Outlet />
    </ErrorBoundary>
  </Sidebar>
);

export default Dashboard;
