import { type ToastId, useToast, type UseToastOptions } from '@chakra-ui/react';
import { useCallback } from 'react';

type CreateToastFn = (options: UseToastOptions) => ToastId;

/**
 * Hook used to trigger toast notifications
 * @returns
 */
const useNotify = (): CreateToastFn => {
  const toast = useToast();

  return useCallback(
    (options: UseToastOptions) =>
      toast({
        ...options,
        position: 'top',
        isClosable: true,
      }),
    [toast],
  );
};

export default useNotify;
