import es from './es.json';
import en from './messages.json';
import pt from './pt.json';

const languages = {
  es,
  pt,
  en,
};

export default languages;
