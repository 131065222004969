import { GtmWindow } from '~sf/env';

type GtmPushProps = {
  event: string;
  eventProps: Record<string, unknown>;
};

declare let window: GtmWindow;

export const gtmDataLayerPush = ({ event, eventProps }: GtmPushProps) => {
  if (!window.dataLayer) {
    return;
  }

  // eslint-disable-next-line functional/immutable-data
  window.dataLayer.push({
    event,
    eventProps,
  });
};
