type NominalTypeNames =
  | 'UserId'
  | 'ClinicId'
  | 'ClinicStaffId'
  | 'PatientId'
  | 'ProtocolId'
  | 'EcgBundleId'
  | 'MedicationDosageId'
  | 'MedicationDefinitionId'
  | 'EcgRecordingId'
  | 'EcgDataFileId'
  | 'EcgAnalysisId';

export const NominalTypes: {
  [Key in NominalTypeNames]: Key;
} = {
  UserId: 'UserId',
  ClinicId: 'ClinicId',
  ClinicStaffId: 'ClinicStaffId',
  PatientId: 'PatientId',
  ProtocolId: 'ProtocolId',
  EcgBundleId: 'EcgBundleId',
  MedicationDosageId: 'MedicationDosageId',
  MedicationDefinitionId: 'MedicationDefinitionId',
  EcgRecordingId: 'EcgRecordingId',
  EcgDataFileId: 'EcgDataFileId',
  EcgAnalysisId: 'EcgAnalysisId',
};

type NominalType<Brand extends symbol> = { readonly __brand__: Brand };

const UserIdSymbol = Symbol(NominalTypes.UserId);
type UserId = NominalType<typeof UserIdSymbol>;

const ClinicIdSymbol = Symbol(NominalTypes.ClinicId);
type ClinicId = NominalType<typeof ClinicIdSymbol>;

const ClinicStaffIdSymbol = Symbol(NominalTypes.ClinicStaffId);
type ClinicStaffId = NominalType<typeof ClinicStaffIdSymbol>;

const PatientIdSymbol = Symbol(NominalTypes.PatientId);
type PatientId = NominalType<typeof PatientIdSymbol>;

const ProtocolIdSymbol = Symbol(NominalTypes.ProtocolId);
type ProtocolId = NominalType<typeof ProtocolIdSymbol>;

const EcgBundleIdSymbol = Symbol(NominalTypes.EcgBundleId);
type EcgBundleId = NominalType<typeof EcgBundleIdSymbol>;

const MedicationDosageIdSymbol = Symbol(NominalTypes.MedicationDosageId);
type MedicationDosageId = NominalType<typeof MedicationDosageIdSymbol>;

const MedicationDefinitionIdSymbol = Symbol(NominalTypes.MedicationDefinitionId);
type MedicationDefinitionId = NominalType<typeof MedicationDefinitionIdSymbol>;

const EcgRecordingIdSymbol = Symbol(NominalTypes.EcgRecordingId);
type EcgRecordingId = NominalType<typeof EcgRecordingIdSymbol>;

const EcgDataFileIdSymbol = Symbol(NominalTypes.EcgDataFileId);
type EcgDataFileId = NominalType<typeof EcgDataFileIdSymbol>;

const EcgAnalysisIdSymbol = Symbol(NominalTypes.EcgAnalysisId);
type EcgAnalysisId = NominalType<typeof EcgAnalysisIdSymbol>;

export type {
  ClinicId,
  ClinicStaffId,
  EcgAnalysisId,
  EcgBundleId,
  EcgDataFileId,
  EcgRecordingId,
  MedicationDefinitionId,
  MedicationDosageId,
  PatientId,
  ProtocolId,
  UserId,
};

// Conversion Methods between number and nominal types
export const UserIdType = (id: number | UserId): UserId => id as unknown as UserId;

export const ClinicIdType = (id: number | ClinicId): ClinicId => id as unknown as ClinicId;

export const ClinicStaffIdType = (id: number | ClinicStaffId): ClinicStaffId =>
  id as unknown as ClinicStaffId;

export const PatientIdType = (id: number | PatientId): PatientId => id as unknown as PatientId;

export const ProtocolIdType = (id: number | ProtocolId): ProtocolId => id as unknown as ProtocolId;

export const EcgBundleIdType = (id: number | EcgBundleId): EcgBundleId =>
  id as unknown as EcgBundleId;

export const MedicationDosageIdType = (id: number | MedicationDosageId): MedicationDosageId =>
  id as unknown as MedicationDosageId;

export const MedicationDefinitionIdType = (
  id: number | MedicationDefinitionId,
): MedicationDefinitionId => id as unknown as MedicationDefinitionId;

export const EcgRecordingIdType = (id: number | EcgRecordingId): EcgRecordingId =>
  id as unknown as EcgRecordingId;

export const EcgDataFileIdType = (id: number | EcgDataFileId): EcgDataFileId =>
  id as unknown as EcgDataFileId;

export const EcgAnalysisIdType = (id: number | EcgAnalysisId): EcgAnalysisId =>
  id as unknown as EcgAnalysisId;

export const NumberType = (
  id:
    | ClinicId
    | ClinicStaffId
    | PatientId
    | ProtocolId
    | UserId
    | EcgBundleId
    | MedicationDosageId
    | MedicationDefinitionId
    | EcgRecordingId
    | EcgDataFileId
    | EcgAnalysisId
    | number,
): number => id as unknown as number;
