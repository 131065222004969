import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import ErrorPage, { ErrorContent } from '~sf/pages/ErrorPage';

export type ErrorBoundaryProps = {
  mode?: 'page' | 'component';
  children: React.ReactNode;
};

const ErrorBoundary = ({ children, mode = 'component' }: ErrorBoundaryProps) => (
  <SentryErrorBoundary
    fallback={mode === 'page' ? <ErrorPage /> : <ErrorContent />}
    showDialog={false}
  >
    {children}
  </SentryErrorBoundary>
);

export default ErrorBoundary;
