import {
  Box,
  type BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FaDatabase, FaHeartbeat } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight, FiMenu } from 'react-icons/fi';
import { FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { AppRouteEntitlements, AppRoutes } from '~sf/components/setup/MainRouter/constants';
import Logo from '~sf/components/ui/Logo';
import useIsMobile from '~sf/utils/hooks/useIsMobile';
import usePersistedState from '~sf/utils/hooks/usePersistedState';
import useStore from '~sf/utils/hooks/useStore';
import isUndefined from '~sf/utils/isUndefined';

import AboutUsIcon from './assets/about-us.svg';
import ClinicStaffIcon from './assets/clinic_staff.svg';
import ClinicsIcon from './assets/clinics.svg';
import ContactUsIcon from './assets/contact-us.svg';
import DevicesIcon from './assets/devices.svg';
import HomeIcon from './assets/home.svg';
import PatientsIcon from './assets/patients.svg';
import ProvidersIcon from './assets/providers.svg';
import { MESSAGES } from './messages';
import NavItem from './NavItem';

const SIDEBAR_WIDTH_EXPANDED = '200px';
const SIDEBAR_WIDTH_COLLAPSED = 20;

type LinkItemProps = {
  name: MessageDescriptor;
  icon: string | JSX.Element;
  path: AppRoutes;
  isCollapsed: boolean;
  onClick?: () => void;
};

const SidebarEntries: Omit<LinkItemProps, 'isCollapsed'>[] = [
  {
    name: MESSAGES.Patients,
    icon: PatientsIcon,
    path: AppRoutes.Patients,
  },
  {
    name: MESSAGES.EcgReview,
    icon: <FaHeartbeat />,
    path: AppRoutes.EcgReview,
  },
  {
    name: MESSAGES.PatientHome,
    icon: HomeIcon,
    path: AppRoutes.PatientHome,
  },
  {
    name: MESSAGES.Clinics,
    icon: ClinicsIcon,
    path: AppRoutes.Clinics,
  },
  {
    name: MESSAGES.Providers,
    icon: ProvidersIcon,
    path: AppRoutes.Providers,
  },
  {
    name: MESSAGES.Clinic_Staff,
    icon: ClinicStaffIcon,
    path: AppRoutes.Staff,
  },
  {
    name: MESSAGES.Device,
    icon: DevicesIcon,
    path: AppRoutes.Devices,
  },
  {
    name: MESSAGES.CCT_Datasets,
    icon: <FaDatabase />,
    path: AppRoutes.AnnotationJobs,
  },
  {
    name: MESSAGES.Contact_us,
    icon: ContactUsIcon,
    path: AppRoutes.Contact,
  },
];

const SidebarItem = ({ name, icon, path, isCollapsed, onClick }: LinkItemProps) => {
  const intl = useIntl();

  return (
    <NavItem
      key={name.id}
      to={path}
      icon={icon}
      isCollapsed={isCollapsed}
      title={intl.formatMessage(name)}
      onClick={onClick}
    >
      <FormattedMessage {...name} />
    </NavItem>
  );
};

export type SidebarProps = {
  children?: React.ReactNode;
};

const Sidebar = ({ children }: SidebarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = usePersistedState(false, 'isSidebarCollapsed');

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile && isCollapsed) {
      setIsCollapsed(false);
    }
  }, [isMobile, isCollapsed, setIsCollapsed]);

  return (
    <Box minH="100vh" bgColor="bgMain">
      <SidebarContent
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        display={{ base: 'none', md: 'block' }}
        onClick={() => onClose}
      />

      <Drawer
        isOpen={isOpen}
        placement="left"
        returnFocusOnClose={false}
        size="full"
        onClose={onClose}
        onOverlayClick={onClose}
      >
        <DrawerContent>
          <SidebarContent
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            onClick={onClose}
          />
        </DrawerContent>
      </Drawer>

      <IconButton
        top={5}
        left={2}
        position="absolute"
        display={{ base: 'flex', md: 'none' }}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
        onClick={onOpen}
      />

      <Box maxW="100vw">
        <Box
          ml={{
            base: 0,
            md: isCollapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH_EXPANDED,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

interface SidebarContentProps extends BoxProps {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
  onClick: () => void;
}

const SidebarContent = ({ onClick, isCollapsed, setIsCollapsed, ...rest }: SidebarContentProps) => {
  const { entitlements } = useStore((state) => ({
    entitlements: state.entitlements,
  }));

  return (
    <Box
      bg="bgAccent"
      borderRight="1px"
      borderRightColor={useColorModeValue('muted.200', 'muted.700')}
      w={{
        base: 'full',
        md: isCollapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH_EXPANDED,
      }}
      pos="fixed"
      h="full"
      zIndex={3}
      shadow="sm"
      color="muted.300"
      {...rest}
    >
      <Flex direction="column" w="100%" h="100%" pb={3}>
        <HStack h="20" gap={0} px={3} w="100%">
          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <Link
            as={NavLink}
            to="/"
            role="link"
            fontSize="2xl"
            fontFamily="monospace"
            fontWeight="bold"
            mx="auto"
            maxW="80%"
            onKeyDown={onClick}
            onClick={onClick}
          >
            <Logo variant={isCollapsed ? 'small' : 'normal'} />
          </Link>

          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClick} />
        </HStack>

        {SidebarEntries.filter((menu) => {
          const entitlement = AppRouteEntitlements[menu.path];

          if (
            menu.path === AppRoutes.PatientHome &&
            entitlements.includes('clinic.list_patientpageresource_entitlement')
          ) {
            // Special case, we only show this one if the user can't list all patients
            // which is a way to filter out admins
            return false;
          }

          if (!isUndefined(entitlement)) {
            return entitlements.includes(entitlement);
          }

          return true;
        }).map((item) => (
          <SidebarItem key={item.name.id} isCollapsed={isCollapsed} onClick={onClick} {...item} />
        ))}

        <Spacer />

        <SidebarItem
          key={AppRoutes.About}
          isCollapsed={isCollapsed}
          icon={AboutUsIcon}
          name={MESSAGES.About}
          path={AppRoutes.About}
          onClick={onClick}
        />

        <Box
          position="absolute"
          top={9}
          right="-13px"
          textAlign="right"
          display={{ base: 'none', md: 'block' }}
          background="white"
          shadow="md"
          borderRadius="50%"
          h={6}
          w={6}
          overflow="hidden"
        >
          <IconButton
            p={0}
            m={0}
            w={6}
            h={6}
            color="muted.800"
            position="relative"
            left="-0.5em"
            top="-0.05em"
            title="Collapse/Expand Sidebar"
            aria-label="Collapse/Expand sidebar"
            icon={isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
            variant="ghost"
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default Sidebar;
