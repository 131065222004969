import { extendTheme, theme as baseTheme, type ThemeConfig } from '@chakra-ui/react';

import * as overrides from './overrides';

const config: ThemeConfig = {
  initialColorMode: 'light',
  // useSystemColorMode: true,
};

// Custom colors
const red = {
  ...baseTheme.colors.red,
  500: '#c1272e',
  600: '#981016',
  900: '#620307',
};

export const primary = {
  ...baseTheme.colors.cyan,
  500: baseTheme.colors.cyan[700],
  600: baseTheme.colors.cyan[700],
};

export const icon = {
  ...baseTheme.colors.gray,
  600: baseTheme.colors.gray[400],
};

const safebeatTheme = extendTheme({
  config,
  fonts: {
    body: `Inter,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    heading: `Inter,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  breakpoints: {
    sm: '40em',
    md: '50em',
    semiLg: '60em',
    lg: '80em',
    xl: '95em',
    '2xl': '100em',
  },

  colors: {
    red,
    brand: red,
    primary,
    focus: baseTheme.colors.cyan,
    secondary: baseTheme.colors.blue,
    positive: baseTheme.colors.green,
    negative: red,
    warning: baseTheme.colors.orange,
    information: baseTheme.colors.blue,
    bgMain: '#fafafa',
    bgAccent: '#2D3748',
    muted: baseTheme.colors.gray,
    icon,
    borderGray: '#E2E8F0',
  },
  components: {
    IconButton: {
      baseStyle: {
        borderRadius: 6,
        p: '8px',
        textShadow: '0 -1px rgb(0 0 0 / 25%)',
        border: '1px solid',
        borderColor: 'gray.200',
        w: '40px',
        h: '40px',
        _hover: {
          backgroundColor: 'gray.100',
        },
        _active: {
          backgroundColor: 'gray.50',
        },
      },
      variants: {
        active: {
          color: 'cyan.700',
        },
        default: {
          color: 'gray.700',
        },
      },
    },
    ...overrides,
  },
});

export default safebeatTheme;
