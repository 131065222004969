import Bowser from 'bowser';

export default function getBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent);

  return browser.getBrowserName();
}

export function isSafari() {
  return getBrowser() === 'Safari';
}
