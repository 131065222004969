import { defineStyleConfig } from '@chakra-ui/react';

export const Text = defineStyleConfig({
  baseStyle: {},
  sizes: {},
  variants: {
    h3: {
      fontSize: '18px',
      fontWeight: 'semibold',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    h4: {
      fontSize: '16px',
      fontWeight: 'semibold',
      lineHeight: '22px',
      letterSpacing: '0.4px',
    },
  },
  defaultProps: {},
});
