import { ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useState } from 'react';

import Button from '~sf/components/ui/Button';
import Modal from '~sf/components/ui/Modal';
import isNullOrUndefined from '~sf/utils/isNullOrUndefined';

import TrialDisclaimer from './TrialDisclaimer';
import useIsClinicalTrial from './useIsClinicalTrial';

const TRIAL_SOFTWARE_MODAL_KEY = 'hasSeenTrialSoftwareModal';

/**
 * Trial Software disclaimer that is shown to clinic users when they first log in with a new device
 */
const TrialSoftwareModal = () => {
  const [hasSeenModal, setHasSeenModal] = useState<boolean>(hasSeenTrialSoftwareModal());
  const isClinicalTrialUser = useIsClinicalTrial();

  if (!isClinicalTrialUser || hasSeenModal) {
    return null;
  }

  const onClose = () => {
    setHasSeenTrialSoftwareModal();
    setHasSeenModal(true);
  };

  return (
    <Modal isOpen size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Disclaimer</ModalHeader>
        <ModalBody>
          <TrialDisclaimer />
        </ModalBody>

        <ModalFooter>
          <Button eventName="trial-disclaimer--accept" colorScheme="primary" onClick={onClose}>
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

function hasSeenTrialSoftwareModal() {
  return !isNullOrUndefined(localStorage.getItem(TRIAL_SOFTWARE_MODAL_KEY));
}

function setHasSeenTrialSoftwareModal() {
  localStorage.setItem(TRIAL_SOFTWARE_MODAL_KEY, 'true');
}

export default TrialSoftwareModal;
