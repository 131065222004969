import { ChakraProvider } from '@chakra-ui/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type React from 'react';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';

import QueryContext from '~sf/api/QueryContext';
import ErrorBoundary from '~sf/components/ui/ErrorBoundary';
import translationMessages from '~sf/i18n';
import LoadingPage from '~sf/pages/LoadingPage';

import safebeatTheme from './theme';

const DEFAULT_LOCALE = 'en';

export type SetupContextProps = {
  children: React.ReactNode;
};

const SetupContext = ({ children }: SetupContextProps) => {
  const AppContent = (
    <ChakraProvider resetCSS theme={safebeatTheme}>
      <HelmetProvider>
        <QueryContext>
          <Suspense fallback={<LoadingPage />}>
            <ErrorBoundary mode="page">
              {/* ReactQueryDevtools are automatically excluded from prod builds */}
              <ReactQueryDevtools initialIsOpen={false} />

              {children}
            </ErrorBoundary>
          </Suspense>
        </QueryContext>
      </HelmetProvider>
    </ChakraProvider> // TODO: Workaround for an issue in react-intl
  ) as IntlProvider['props']['children'];

  return (
    <IntlProvider
      messages={translationMessages[DEFAULT_LOCALE]}
      locale={DEFAULT_LOCALE}
      defaultLocale={DEFAULT_LOCALE}
    >
      {AppContent}
    </IntlProvider>
  );
};

export default SetupContext;
