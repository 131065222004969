import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { MdUpdate } from 'react-icons/md';

import Button from '~sf/components/ui/Button';
import InlineIcon from '~sf/components/ui/InlineIcon';

import checkVersionIsLatest from './checkVersionIsLatest';
import { VERSION_CHECK_INTERVAL, VERSION_RELOAD_FLAG } from './constants';

/**
 * Show a modal dialog whenever the current version is out of date so the user can reload the page
 * if something goes wrong.
 *
 * This uses the file in `public/version.json` to check the latest version, as that one is the same file
 * for all builds.
 *
 * Once called this method sets up an interval to check every minute.
 */
const CheckVersion = () => {
  const hasAcknowledged = localStorage.getItem(VERSION_RELOAD_FLAG) === 'true';

  const [isOutdated, setIsOutdated] = useBoolean(false);
  const [isAcknowledged, setIsAcknowledged] = useBoolean(hasAcknowledged);

  const checkVersion = useCallback(async () => {
    const isLatestVersion = await checkVersionIsLatest();

    if (isLatestVersion) {
      localStorage.removeItem(VERSION_RELOAD_FLAG);
      setIsOutdated.off();
    } else {
      setIsOutdated.on();
    }
  }, [setIsOutdated]);

  useEffect(() => {
    void checkVersion();
    const intervalId = setInterval(async () => checkVersion(), VERSION_CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [checkVersion]);

  const onAcknowledge = () => {
    localStorage.setItem(VERSION_RELOAD_FLAG, 'true');

    setIsAcknowledged.on();
  };

  const onReload = () => {
    localStorage.setItem(VERSION_RELOAD_FLAG, 'true');

    window.location.reload();
  };

  const showModal = isOutdated && !isAcknowledged;

  return (
    <Modal isOpen={showModal} onClose={onAcknowledge}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <InlineIcon icon={<MdUpdate />} mr={2} />
          Update available
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          There is a new version of Safebeat available, please reload the page as soon as possible
          to avoid any issues.
        </ModalBody>

        <ModalFooter>
          <Button eventName="check-version--later" variant="ghost" mr={3} onClick={onAcknowledge}>
            Later
          </Button>

          <Button eventName="check-version--update" colorScheme="primary" onClick={onReload}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CheckVersion;
