import { ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { setupFetcher } from './api/fetch';
import CheckVersion from './components/app/CheckVersion';
import TrialSoftwareModal from './components/app/TrialSoftwareModal';
import CheckAuth from './components/setup/CheckAuth';
import MainRouter from './components/setup/MainRouter';
import SetupContext from './components/setup/SetupContext';
import * as serviceWorker from './pwa/serviceWorker';
import setupMsw from './utils/msw';
import { setupMixpanel } from './vendor/mixpanel';
import { setupSentry } from './vendor/sentry';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  setupFetcher();
  setupMixpanel();
  setupSentry();
  void setupMsw();

  root.render(
    // StrictMode is only active in local development. WARNING: It will render the tree twice!
    <React.StrictMode>
      <BrowserRouter>
        <SetupContext>
          <ColorModeScript />
          <CheckAuth />
          <CheckVersion />
          <TrialSoftwareModal />

          <MainRouter />
        </SetupContext>
      </BrowserRouter>
    </React.StrictMode>,
  );
} else {
  throw new Error('No container found');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
