import type { TrendThresholds } from '~sf/components/ui/TrendLine';
import { importMetaEnv } from '~sf/constants';

export const API_URL = importMetaEnv.VITE_API_URL || 'http://localhost:8000';

// TODO: Move these to backend, in some sort of "clinic settings" API
export const QTcThresholds: TrendThresholds = {
  upper: 500,
  upperWarning: 460,
};

// Cutoff values for "realistic" QTc values
export const QTcMinMax = {
  min: 275,
  max: 650,
};

export const QRSMinMax = {
  min: 10,
  max: 300,
};

export const HrMinMax = {
  min: 20,
  max: 350,
};

export const HRThresholds: TrendThresholds = {
  upper: 100,
  upperWarning: 90,
  lowerWarning: 60,
  lower: 50,
};

export const CreatinineThresholds: TrendThresholds = {
  upper: 8,
  upperWarning: 1.3,
  lowerWarning: 0.6,
  lower: 0.3,
};

export const BradycardiaThreshold = 50;
export const TachycardiaThreshold = 100;

const hrToRrRatio = 1000 / 60;

export const RRThresholds: TrendThresholds = {
  upper: hrToRrRatio * HRThresholds.upper!,
  upperWarning: hrToRrRatio * HRThresholds.upperWarning!,
  lowerWarning: hrToRrRatio * HRThresholds.lowerWarning!,
  lower: hrToRrRatio * HRThresholds.lower!,
};

export const QRSThresholds: TrendThresholds = {
  upperWarning: 120,
};

export const PRThresholds: TrendThresholds = {
  upperWarning: 200,
};

export const CrClThresholds: TrendThresholds = {
  lowerWarning: 61,
  lower: 20,
};

export const NAME_MAX_LENGTH = 50;
export const NAME_MIN_LENGTH = 2;
export const PASSWORD_MAX_LENGTH = 50;
export const PASSWORD_MIN_LENGTH = 2;
export const PHONE_NO_MAX_LENGTH = 20;
export const ONE_HOUR = 60 * 60 * 1000; /* ms */
export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;
export const INVALID_INDEX = -1;

export const DEFAULT_MAX_DOSE = 5;
