import type { Entitlements } from '~sf/models';

export enum AppRoutes {
  About = '/about',
  SignIn = '/sign-in',
  Contact = '/contact',
  ForgotPasswd = '/forgot-password',
  ForgotPasswdReset = '/password-reset',
  MFA = '/mfa-authentication',
  MagicLink = '/magic-link-login',
  AnnotationJobs = '/annotations',
  Patients = '/patients',
  PatientHome = '/patient-home',
  Clinics = '/clinics',
  Staff = '/clinic-staff',
  Providers = '/providers',
  Devices = '/devices',
  EcgReview = '/ecg-review',
}

/**
 * This map is used to determine the entitlements required to access a given route.
 * It will be read in order to determine which page to load given the entitlements.
 */
export const AppRouteEntitlements: {
  [key in AppRoutes]: Entitlements | undefined;
} = {
  [AppRoutes.Patients]: 'clinic.list_patientpageresource_entitlement',
  [AppRoutes.AnnotationJobs]: 'annotation_portal.list_annotationjobpageresource_entitlement',
  [AppRoutes.Clinics]: 'clinic.list_clinicpageresource_entitlement',
  [AppRoutes.Staff]: 'clinic.list_clinicstaffpageresource_entitlement',
  [AppRoutes.Providers]: 'clinic.list_clinicstaffpageresource_entitlement',
  [AppRoutes.Devices]: 'device.list_devicepageresource_entitlement',
  [AppRoutes.PatientHome]: 'clinic.view_patient_client_patientpageresource_entitlement',
  [AppRoutes.EcgReview]:
    'drug_loading_protocol.view_ecg_review_page_drugloadingprotocolpageresource_entitlement',
  // Routes not requiring entitlement checks
  [AppRoutes.About]: undefined,
  [AppRoutes.SignIn]: undefined,
  [AppRoutes.Contact]: undefined,
  [AppRoutes.ForgotPasswd]: undefined,
  [AppRoutes.ForgotPasswdReset]: undefined,
  [AppRoutes.MFA]: undefined,
  [AppRoutes.MagicLink]: undefined,
};
