import mixpanel_browser, { type Callback, type Dict, type RequestOptions } from 'mixpanel-browser';

import { importMetaEnv, MIXPANEL_TOKEN, TRACKED_ENVIRONMENTS } from '~sf/constants';
import type { UserProfileResponse } from '~sf/models';

const isTrackingEnabled = TRACKED_ENVIRONMENTS.includes(importMetaEnv.VITE_ENV_NAME);

export const setupMixpanel = () => {
  try {
    mixpanel_browser.init(MIXPANEL_TOKEN, { debug: true });
    mixpanel_browser.track('setup');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Mixpanel is not available', e);
  }
};

const mixpanel = {
  identify: (unique_id?: string | undefined) => {
    if (!isTrackingEnabled) {
      return;
    }

    mixpanel_browser.identify(unique_id);
  },
  alias: (eventName: string, original?: string | undefined) => {
    if (!isTrackingEnabled) {
      return;
    }

    mixpanel_browser.alias(eventName, original);
  },
  track: (
    eventName: string,
    properties?: Dict | undefined,
    optionsOrCallback?: RequestOptions | Callback | undefined,
    callback?: Callback | undefined,
  ) => {
    if (!isTrackingEnabled) {
      return;
    }

    mixpanel_browser.track(eventName, properties, optionsOrCallback, callback);
  },
  setSuperProperties: (profile: UserProfileResponse) => {
    if (!isTrackingEnabled) {
      return;
    }

    // TODO: Remove Role access
    mixpanel_browser.register({
      env: importMetaEnv.VITE_ENV_NAME,
      role: profile.role,
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      user_profile_id: profile.id,
    });
  },
};

export default mixpanel;
