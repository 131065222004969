import { Flex, type FlexProps, Image, Link } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

import InlineIcon from '~sf/components/ui/InlineIcon';

export interface NavItemProps extends FlexProps {
  to?: string;
  icon: string | JSX.Element;
  isCollapsed: boolean;
  children: ReactNode;
  onClick?: () => void;
}

const NavItem = ({ to, icon, children, isCollapsed, onClick, ...rest }: NavItemProps) => {
  const resolved = useResolvedPath(to || '');
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <Link
      as={NavLink}
      to={to || ''}
      role="link"
      display="block"
      fontWeight={match ? 'bold' : 'normal'}
      color={match ? 'white' : 'inherit'}
      background={match ? 'rgba(255, 255, 255, 0.04)' : 'transparent'}
      _hover={{
        bg: 'rgba(255, 255, 255, 0.04)',
      }}
      px={!isCollapsed ? 4 : 3}
      py={3}
      mx={4}
      my={1}
      cursor="pointer"
      borderRadius="lg"
      title={rest.title}
      onKeyDown={onClick}
      onClick={onClick}
    >
      <Flex align="center" role="group" width="max-content" {...rest} alignItems="center">
        {icon && typeof icon === 'string' ? (
          <Image
            mr={!isCollapsed ? '4' : 0}
            height="24px"
            width="24px"
            _groupHover={{}}
            src={icon}
          />
        ) : null}

        {icon && typeof icon === 'object' ? (
          <InlineIcon icon={icon} mr={!isCollapsed ? '4' : 0} />
        ) : null}

        {!isCollapsed ? children : null}
      </Flex>
    </Link>
  );
};

export default NavItem;
