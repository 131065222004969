import { Image, type ImageProps } from '@chakra-ui/react';

import LogoDark from './assets/logo-dark.svg';
import LogoRed from './assets/logo-red.png';
import LogoImgSmall from './assets/logo-small.svg';
import LogoWhite from './assets/logo-white.svg';

export type LogoProps = {
  variant?: 'normal' | 'small' | 'dark' | 'red';
} & ImageProps;

const LogoVariants = {
  normal: LogoWhite,
  small: LogoImgSmall,
  dark: LogoDark,
  red: LogoRed,
};

const Logo = ({ variant = 'normal', ...rest }: LogoProps) => (
  <Image
    src={LogoVariants[variant]}
    w={variant === 'small' ? '30px' : '100%'}
    objectFit="contain"
    {...rest}
  />
);

export default Logo;
