import { Suspense } from 'react';

import LoadingPage from '~sf/pages/LoadingPage';

type ComponentLoaderProps = {
  children: React.ReactNode;
};

const ComponentLoader = ({ children }: ComponentLoaderProps) => (
  <Suspense fallback={<LoadingPage />}>{children}</Suspense>
);

export default ComponentLoader;
