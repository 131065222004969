import { type Dispatch, type SetStateAction, useState } from 'react';

import isNullOrUndefined from '~sf/utils/isNullOrUndefined';

const isStorageEnabled = typeof Storage !== 'undefined' && typeof localStorage !== 'undefined';

/**
 * Locally persistent version of useState, with the same API (just defining a unique key for the stored value)
 * It'll store whatever value you set in localStorage, and retrieve it
 * to replace the default value if available.
 */
const usePersistedState = <S>(defaultValue: S, key: string): [S, Dispatch<SetStateAction<S>>] => {
  let initialValue = defaultValue;

  if (isStorageEnabled) {
    const persistedValue = localStorage.getItem(key);

    if (!isNullOrUndefined(persistedValue)) {
      initialValue = JSON.parse(persistedValue) as S;
    }
  }

  const [value, setValue] = useState(initialValue);

  const persistValue: typeof setValue = (newValue) => {
    if (isStorageEnabled) {
      localStorage.setItem(key, JSON.stringify(newValue));
    }

    setValue(newValue);
  };

  return [value, persistValue];
};

export default usePersistedState;
