import { ModalContent, ModalHeader } from '@chakra-ui/react';
import { Children, type ReactElement, type ReactNode } from 'react';

import isNullOrUndefined from '~sf/utils/isNullOrUndefined';
import type { ArrayElement } from '~sf/utils/ts/arrayType';

export function getModalHeaderText(modalChildren: ReactNode): string | undefined {
  const childrenArray = Children.toArray(modalChildren);

  const modalContent = childrenArray.find(
    (child) => isReactElement(child) && child.type === ModalContent,
  );

  if (isNullOrUndefined(modalContent) || !isReactElement(modalContent)) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const modalContentChildren = Children.toArray(modalContent.props.children as ReactElement);

  const modalHeader = modalContentChildren.find(
    (child) => isReactElement(child) && child.type === ModalHeader,
  );

  return getComponentText(modalHeader as ReactElement);
}

function getComponentText(component: ReactElement | string | number | undefined): string {
  if (!component) {
    return '';
  }
  if (typeof component === 'string' || typeof component === 'number') {
    return String(component);
  }

  if (!('children' in component.props)) {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const children = component.props.children as ReactElement;

  if (children instanceof Array) {
    return children.map(getComponentText).join('');
  }

  return getComponentText(children);
}

type ChildrenType = ArrayElement<ReturnType<typeof Children.toArray>>;

function isReactElement(arg: ChildrenType): arg is React.ReactElement {
  // eslint-disable-next-line no-prototype-builtins
  return arg.hasOwnProperty('type');
}
