import { defineMessages } from 'react-intl';

const componentId = 'sidebar';

export const MESSAGES = defineMessages({
  Providers: {
    id: `${componentId}.Providers`,
    defaultMessage: 'Providers',
  },
  Clinics: {
    id: `${componentId}.Clinics`,
    defaultMessage: 'Clinics',
  },
  Clinic_Staff: {
    id: `${componentId}.Clinic_Staff`,
    defaultMessage: 'Clinic Staff',
  },
  PatientHome: {
    id: `${componentId}.PatientHome`,
    defaultMessage: 'Home',
  },
  Patients: {
    id: `${componentId}.Patients`,
    defaultMessage: 'Patients',
  },
  EcgReview: {
    id: `${componentId}.EcgReview`,
    defaultMessage: 'ECG Review',
  },
  Contact_us: {
    id: `${componentId}.Contact_us`,
    defaultMessage: 'Contact us',
  },
  Device: {
    id: `${componentId}.Device`,
    defaultMessage: 'Devices',
  },
  CCT_Datasets: {
    id: `${componentId}.CCT`,
    defaultMessage: 'Annotations',
  },
  About: {
    id: `${componentId}.About`,
    defaultMessage: 'About Us',
  },
});
