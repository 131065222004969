import { Modal as ChakraModal, type ModalProps as ChakraModalProps } from '@chakra-ui/react';
import { useEffect } from 'react';

import isNullOrUndefined from '~sf/utils/isNullOrUndefined';
import mixpanel from '~sf/vendor/mixpanel';

import { getModalHeaderText } from './getModalHeaderText';

export type ModalProps = {
  modalEventName?: string;
  eventProps?: { [key: string]: string | number | boolean };
} & ChakraModalProps;

/**
 * Modal that tracks an event when it's opened, the event name is taken
 * from the ModalHeader element inside.
 */
const Modal = ({ children, modalEventName, eventProps, ...rest }: ModalProps) => {
  const { isOpen } = rest;

  useEffect(() => {
    try {
      const modalHeaderText = modalEventName ?? getModalHeaderText(children);

      if (!isOpen || isNullOrUndefined(modalHeaderText)) {
        return;
      }

      mixpanel.track(`Modal View: ${modalHeaderText}`, eventProps);
    } catch (_) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return <ChakraModal {...rest}>{children}</ChakraModal>;
};

export default Modal;
