import { useQuery } from '@tanstack/react-query';

import { getClinicDataClient } from '~sf/api/clients';
import { QueryKey } from '~sf/api/query';
import { ClinicIdType } from '~sf/api/tooling/nominal-types';
import type { ClinicModel } from '~sf/models';
import isNullOrUndefined from '~sf/utils/isNullOrUndefined';

import useProfile from './useProfile';

function useGetClinic(): ClinicModel | null {
  const profile = useProfile();

  const clinicId = profile?.clinic?.id;
  const hasClinic = !isNullOrUndefined(clinicId);

  const clinicIdType = ClinicIdType(hasClinic ? clinicId : 0);

  const { data: response } = useQuery({
    queryKey: QueryKey.Clinic(clinicIdType),
    queryFn: async () => getClinicDataClient({ clinic_id: clinicIdType }),
    enabled: hasClinic,
    retry: false,
  });

  if (!hasClinic || !response?.data) {
    return null;
  }

  return response.data;
}

export default useGetClinic;
